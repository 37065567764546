// @ts-ignore
import { orgAxios } from '../api/axios';

export const HBACandidateDataService = {
  getCandidateData,
};

async function getCandidateData(body: any) {
  // @ts-ignore
  return await orgAxios.get(`/cds/candidate/${body.candidateId}`);
}
