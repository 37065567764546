import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Button } from '@amzn/stencil-react-components/button';

const TokenDisplay: React.FC = () => {
  const auth = useAuth();
  const [isExpanded, setIsExpanded] = React.useState(false);

  if (!auth.user?.id_token) {
    return null;
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        padding: '16px',
        maxWidth: '500px',
        zIndex: 9999,
      }}
    >
      <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '8px' }}>
        Development Token Info
        <Button onClick={() => setIsExpanded(!isExpanded)} style={{ marginLeft: '8px' }}>
          {isExpanded ? 'Hide' : 'Show'}
        </Button>
      </div>

      {isExpanded && (
        <div>
          <div style={{ marginBottom: '16px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>ID Token:</div>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                padding: '8px',
                borderRadius: '4px',
                marginTop: '4px',
                maxHeight: '100px',
                overflow: 'auto',
              }}
            >
              <div style={{ fontSize: '12px', wordBreak: 'break-all' }}>{auth.user.id_token}</div>
            </div>
            <Button onClick={() => copyToClipboard(auth.user?.id_token || '')} style={{ marginTop: '4px' }}>
              Copy ID Token
            </Button>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Access Token:</div>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                padding: '8px',
                borderRadius: '4px',
                marginTop: '4px',
                maxHeight: '100px',
                overflow: 'auto',
              }}
            >
              <div style={{ fontSize: '12px', wordBreak: 'break-all' }}>{auth.user.access_token}</div>
            </div>
            <Button onClick={() => copyToClipboard(auth.user?.access_token || '')} style={{ marginTop: '4px' }}>
              Copy Access Token
            </Button>
          </div>

          <div>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Decoded ID Token Payload:</div>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                padding: '8px',
                borderRadius: '4px',
                marginTop: '4px',
                maxHeight: '200px',
                overflow: 'auto',
              }}
            >
              <pre style={{ fontSize: '12px', margin: 0, whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(auth.user, null, 2)}
              </pre>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TokenDisplay;
